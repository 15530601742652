<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.show_conclusions") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <!-- vaqtincha to'xtatildi -->
              <!-- <el-button @click="back()">{{ $t("message.back") }}</el-button> -->

              <!-- {{ model }}
              <el-button
                v-can="'printCenter.print'"
                :loading="waitingPrint"
                @click="print('conclusion/print', model.id)"
                >{{ $t("message.print") }}</el-button
              > -->
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="modal-body">
      <div class="live-Editor">
        <!-- <div class="live-Editor-width"> -->
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form ref="form" status-icon>
              <div class="Editor_widht" style="margin 40px">
                <el-form-item>
                  <Tinymce
                    :id="tinymceId"
                    ref="editorIn"
                    class="naw_Editor"
                    v-model="tiny_content"
                    :height="800"
                    :width="795"
                  />
                </el-form-item>
              </div>
            </el-form>
          </el-col>
          <el-col :span="16">
            <div
              v-loading="contentLoading"
              v-html="header"
              style="
                height: 800px;
                overflow-y: scroll;
                margin: 0mm 10mm 0mm 70mm;
              "
            ></div>
          </el-col>
        </el-row>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawerChild from "@/utils/mixins/drawer-child";
import Tinymce from "@/components/Tinymce";
export default {
  props: {
    reloadModel: { type: Boolean, default: true },
    selected_id: { default: null },
    content: { default: null },
    form: { default: null },
    order_model: { default: null },
  },
  components: {
    Tinymce,
    drawerChild,
  },
  data() {
    return {
      activeName: "first",
      tinymceId: "watch_conslusion_tiny",
      waitingPrint: false,
      waiting: false,
      tablePatientData: [],
      tableOrderData: [],
      tableServicesData: [],
      data: {},
      tiny_content: "",
      contentLoading: false,
    };
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
    tiny_content: {
      handler: function () {
        if (this.tiny_content) {
          // this.contentLoading = true;
          this.form.content = this.tiny_content;
          const query = {
            form: this.form,
            order_model: this.order_model,
          };
          this.getHeader(query).then((res) => {
            // this.contentLoading = false;
          });
        }
      },
      deep: true,
    },
  },
  created() {},
  async mounted() {
    this.tiny_content = this.content;
  },
  computed: {
    ...mapGetters({
      //   rules: "laboratoryConclusions/rules",
      header: "conclusions/header",
      //   model: "laboratoryConclusions/model",
      //   columns: "laboratoryConclusions/columns",
    }),
  },
  methods: {
    ...mapActions({
      getHeader: "conclusions/getHeader",
      //   save: "laboratoryConclusions/update",
      //   showModel: "laboratoryConclusions/showOrder",
      //   getOrder: "orders/relationShow",
      //   backTo: "orders/back",
    }),
    loadModel() {
      //   this.form = JSON.parse(JSON.stringify(this.model));
    },
    async afterOpened() {
      //   this.waiting = true;
      //   const query = { id: this.selected_id, relation: true };
      //   await this.getOrder(query).then((res) => {
      //     this.tablePatientData.push(res.data.order.patient);
      //     this.tableOrderData.push(res.data.order);
      //     this.tableServicesData = res.data.order.order_services;
      //     this.waiting = false;
      //   });
      //   this.showModel(this.selected_id).then((res) => {
      //     this.data = res.data.patient_conculation;
      //   });
    },
    opened() {},
    afterClosed() {
      //   this.tablePatientData = [];
      //   this.tableOrderData = [];
      //   this.tableServicesData = [];
      //   this.data = {};
    },
    // submit(close = true) {
    //   this.$refs["form"].validate((valid) => {
    //     if (valid) {
    //       this.loadingButton = true;
    //       this.save(this.form)
    //         .then((res) => {
    //           this.loadingButton = false;
    //           this.$emit("c-close", { drawer: "drawerUpdate" });
    //           this.$alert(res);
    //           this.$refs["form"].resetFields();
    //         })
    //         .catch((err) => {
    //           this.loadingButton = false;
    //           this.$alert(err);
    //         });
    //     }
    //   });
    // },
    // back(close = true) {
    //   this.backTo(this.model.order_id).then((res) => {
    //     this.$emit("c-close", { drawer: "drawerUpdate" });
    //     this.$refs["form"].resetFields();
    //   });
    // },
    resetForm(formName) {
      this.$emit("c-close", { drawer: "watchDrawerConclusion" });
      this.$emit("inside_content", this.tiny_content);
    },
  },
};
</script>
<style>
.live-Editor {
  /* min-width: 1700px; */
  /* overflow: hidden; */
}
.live-Editor-width {
  display: flex;
  padding: 30px;
}
</style>
